.filters {
    display: inline-block;
    margin-left: 1.25vw;
    margin-top: 10px;
    position: relative;
}

.filter-select {
    background-color: #396de0;
    color: white;
}

.orientation-div {
    position: absolute;
    width: 180px;
    top: 40px;
    left: 20px;
    background-color: white;
    z-index: 10;
    padding: 10px;
    border-radius: 5px;
}

.search-filter-div {
    width: 100%;
    vertical-align: middle;
    padding: 5px;
    border-radius: 10px;
}

.search-filter-div:hover {
    background-color: #f2f1f9;
}

.search-filter-div img {
    height: 20px;
    width: auto;
    display: inline-block;
}

.search-filter-div p {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 0;
}

.filter-arrow-img {
    height: 38px;
    padding: 12px 10px 12px 0;
    width: auto;
    background-color: #396de0;
    vertical-align: top;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-btn {
    background-color: #396de0;
    border: 2px solid #396de0;
    color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 5px 5px 10px;
    font-weight: bold;
    text-align: left !important;
}

.orientation-btn {
    min-width: 102px;
}

.sort-by-btn {
    min-width: 95px;
}
