@media only screen and (max-width: 480px) {
    .submit-aesthetic-div {
        display: none;
    }

    .aesthetic-ul {
        overflow-x: scroll;
        width: calc(97.5vw - 165px);
        scroll-behavior: smooth;
        margin-bottom: -17px;
    }

}
