.user-header {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.headshot-container {
    display: inline-block;
    margin-right: 1%;
}

.headshot-container img {
    height: 30vh;
    width: 30vh;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.user-info {
    display: inline-block;
    margin-left: 1%;
    max-width: 50%;
}

.user-info p {
    margin-bottom: 0;
}

.name-header {
    color: #c2ba50;
    font-size: 70px;
    margin-bottom: 0;
}

.first-name-margin {
    margin-bottom: -10px;
    margin-top: 10px;
}
.edit-div * {
    display: inline-block;
}

.edit-btn {
    margin-left: 10px;
    margin-right: 5px;
}

.followers-btn {
    border: 1px solid #396de0;
    background-color: white;
    border-radius: 15px;
    margin-left: 5px;
    display: inline-block;
}

.following-btn-width {
    width: 90px;
}

.follow-btn-spacing {
    margin-left: 15px;
    margin-right: 5px;
}

.content-navbar nav {
    border-bottom: 1px solid #c2ba50;
    width: 100%;
    padding-bottom: 0;
}

.edit-modal-div {
    margin: 25px;
}

.headshot-preview {
    width: 175px;
    height: 175px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    object-fit: cover;
}

.prof-upload-btn {
    border: none;
    background-color: white;
    border-radius: 15px;
    margin-left: auto;
    display: block;
    margin-top: 10px;
    color: #737373;
    text-decoration: underline;
}

.profile-details {
    margin-top: 25px;
 }

.aesthetic-header {
    font-weight: bold;
    border-right: 3px solid #c2ba50;
    display: inline-block;
    padding-right: 10px;
}

.aesthetic-btn {
    background-color: white;
    border: 1px solid black;
    border-radius: 25px;
    margin-left: 10px;
}

.upload-aesthetic-p {
    display: inline-block;
    margin-left: 10px;
    text-decoration: underline;
}

.active-nav {
    border-bottom: 3px solid #c2ba50;
}

.save-content-btn {
    position: absolute;
    bottom:   0;
    right: 10px;
}

.delete-content-div {
    display: flex;
    margin-left: 2.5vh;
    min-height: 150px;
    padding-bottom: 25px;
    padding-top: 25px;
}

.delete-content-info {
    display: inline-block;
    flex: 1;
    position: relative;
}

.deleted-content-img-div {
    display: inline-block;
    flex: 1;
    margin-right: 2.5vh;
}

.deleted-content-img-div img {
    width: 100%;
    height: auto;
    border-radius: 25px;
}

.confirm-delete-text {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 8px;
}

.delete-post-btn {
    position: absolute;
    right: 70px;
    bottom: 0;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-weight : bold;
    background-color: white;
    color: red;
    border: 1px solid red;
}

.delete-post-btn:hover {
    background-color: red;
    color: white;
    border: none;
}

.delete-post-btn-right {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-weight : bold;
    background-color: red;
    color: white;
    border: none;
}

.cancel-delete-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-weight : bold;
    background-color: white;
    color: #737373;
    border: 1px solid #737373;
}

.edit-content-modal {
    position: absolute;
    width: 800px;
    background-color: white;
    border: none;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.first-p {
    margin-bottom:0;
}

.after-p {
    margin : 0;
    padding-top:0;
}

.edit-buffer {
    margin-top: 25px;
}

.profile-modal {
    width: 800px;
    margin: auto;
    min-height: 450px;
    margin-top: 15vh;
    background-color: white;
    border: none;
    border-radius: 15px;
    padding-bottom: 5px;
}

.edit-profile-h {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
}

.edit-profile-p {
    text-align: center;
    color: #737373;
}

.connect-with-brands-p {
    color: #737373;
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
}

.creator-biz-link-p {
    color: #737373;
    text-decoration: underline;
    cursor: pointer;
    margin: 5px;
}

.creator-biz-icon {
    height: 25px;
    width: 25px;
    margin-right: 5px;
}

.edit-profile-footer {
    padding-top: 20px;
}

.edit-profile-footer button:last-child {
    float: right;
}

.at-span {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 999;
}

.at-input {
    padding-left: 30px;
}

.social-div {
    padding-top: 5px;
    padding-bottom: 5px;
}

.social-div img {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.social-div img:first-child {
    margin-right: 10px;
}

.upload-photo-div {
    padding-top: 10px;
}

.profile-icon-img {
    height: 25px;
    width: auto;
    display: inline-block;
    margin-right: 5px;
}

.email-verification-banner-div {
    width: 100%;
    background-color: red;
    height: 40px;
    text-align: center;
}

.email-verification-banner-div p {
    color: white;
    margin-top: 8px;
    display: inline-block;
}

.email-confirmation-banner-div {
    width: 100%;
    background-color: #396de0;
    height: 40px;
    text-align: center;
}

.email-confirmation-banner-div p {
    color: white;
    margin-top: 8px;
    display: inline-block;
}

.profile-textarea-limit-span {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #737373;
}

.portfolio-external-disclaimer-div {
    width: 100%;
    background-color: #396de0;
    text-align: center;
    height: 40px;
    position: relative;
}

.portfolio-internal-disclaimer-p {
    color: white;
    padding-top: 8px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}

.portfolio-internal-disclaimer-div {
    width: 100%;
    background-color: #396de0;
    text-align: center;
    height: 40px;
    position: relative;
}

.portfolio-external-disclaimer-p {
    color: white;
    padding-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
}

.portfolio-external-disclaimer-p:last-child {
    color: white;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: -5px;
}

.portfolio-ack-p {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;
    color: white;
}

.username-error-p {
    color: red;
    margin-bottom: 0;
    margin-top: -5px;
    margin-bottom: 10px;
}

.following-headers-div {
    text-align: center;
}

.following-headers-div h3 {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.following-modal {
    width: 500px;
    height: 500px;
    background-color: white;
    border: none;
    border-radius: 15px;
    padding: 25px 10px 25px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.following-search {
    width: 100%;
    border: 2px solid #396de0;
    border-radius: 25px;
    padding: 3px 45px 3px 15px;
    font-weight: bold;
}

.following-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    display: inline-block;
    vertical-align: top;
}

.follow-user-info-div {
    display: inline-block;
    margin-left: 10px;
}

.follow-user-info-div p:last-child {
    margin-bottom: 0;
    color: black;
    margin-top: 5px;
}

.following-username-p {
    margin-bottom: -10px !important;
    margin-top: 8px;
    font-size: 18px;
}

.follow-container {
    overflow: scroll;
    height: 375px;
}

.follow-div {
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
}

.follow-div button {
    float: right;
    margin-top: 7px;
}

.tab-selected {
    border-bottom: 3px solid #396de0;
}

.following-search-div {
    position: relative;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 10px;
}

.following-search-div img {
    position: absolute;
    height: 25px;
    width: auto;
    right: 15px;
    top: 5px;
}

.update-content-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 25px;
}

.update-content-btns button {
    float: right;
    margin-right: 10px;
}

.follow-btn {
    min-width: 92px;
}
