.text-link {
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px #c2ba50 solid;
    font-weight: bold;
}

.final-text-link {
    display: inline-block;
    font-weight: bold;
}

.links {
    position: relative;
    transform: translateY(20%);
}

.search-bar input {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: calc(100% - 70px);
    display: inline-block;
    border-style: solid solid solid none;
    z-index: -1;
    margin-left: -20px;
    padding-left: 70px;
    border-color: #396de0;
    background-color: #fcf5e8;
    border-width: 2px;
}

.search-bar input::placeholder {
    color: #396de0;
    font-family: 'forma-djr-display', sans-serif;
    font-weight: 700;
}

.search-bar input:focus {
    box-shadow: none;
}

.search-bar div a span{
    border-radius: 25px;
}

.dupe-logo {
    width: 70px;
    display: inline-block;
    z-index: 1;
    margin-left: 10px;
    position: relative;
    z-index: 99;
}

.dupe-logo a img {
    background-color: #396de0;
    width: auto;
    height: 40px;
    border-radius: 25px;
    margin-top: -4px;
    padding: 5px 20px 5px 20px;

}

.thumbnail-image {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.profile-dropdown-image {
    height: 80px;
    width: 80px;
    display: inline-block;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-top: 10px;
    margin-right: 10px;
}

.down-arrow {
    height: 8px;
    width: auto;
    margin-left: 5px;
}

.profile {
    float: right;
    margin-right: 15px;
}

.mobile-profile {
    display: none;
}

.dupe-navbar {
    background-color: #fcf5e8;
    padding-top: 10px;
    padding-bottom: 10px;
}

.landing-banner-div {
    background-color: #396de0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 480px) {
    .landing-banner-div {
        display: none;
    }
}

.mobile-button {
    padding: 5px;
    max-height: 45px;
}

.white-background {
    background-color: white;
}

.white-background input {
    background-color: white;
}

.hamburger {
    width: 25px;
    height: auto;
    margin-bottom: 4px;
}

.sidenav {
    width: 350px;
    transition: width 0.3s ease-in-out;
    height: 100vh;
    background-color: #396de0;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 50;
}

.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    display: none;
}

.side-x {
    margin-left: 15px;
    margin-top: 15px;
    color: lightgrey;
}

.menu-options {
    width: 100%;
    text-align: center;
    margin-top: 10vh;
}

.menu-options h3 {
    margin-top: 15px;
    color: white;
}

.menu-options h3:hover {
    text-decoration: underline white;
    text-underline-offset: 5px;
    color: #c2ba50;
}

.login-btn {
    border-radius: 25px;
    background-color: #396de0;
    border: 1px solid #396de0;
    color: white;
    margin-right: 10px;
    width: 70px;
}

.signup-btn {
    border-radius: 25px;
    background-color: white;
    border: 1px solid #396de0;
    color: #396de0;
    margin-right: 10px;
}


.dropdown-info {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: -15px;
    width: calc(100% - 120px);
}

.dropdown-info h5 {
    margin-bottom: -10px;
    color: #396de0;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:18px;
}

.dropdown-info p {
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0 !important;
    width: 100% !important;
    font-size:12px;
    margin-top: -5px;
    padding-top: 0;
}

.dropdown-main-div {
    border-bottom: 1px solid #737373;
    padding-bottom: 10px;
    vertical-align: middle;
    padding-left: 5%;
}

.dropdown-main-div:hover {
    background-color: #f2f1f9;
}

.dropdown-second-div {
    border-bottom: 1px solid #737373;
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 700;
    color: black;
    padding-left: 5%;
}

.dropdown-third-div {
    padding-bottom: 5px;
    padding-top: 10px;
    font-weight: 700;
    padding-left: 5%;
}

.profile-dropdown p:hover {
    cursor: pointer;
    background-color: #f2f1f9;
}

.profile-dropdown p {
    width: 90%;
    border-radius: 10px;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    color: black;
}

.notification-icon-div {
    float: right;
}

.upload-btn-div {
    float: right;
    margin-top: 6px;
    display: inline-block;
}

.notification-img {
    height: 30px;
    width: auto;
    margin-top: 5px;
    margin-right: 10px;
}

.notification-content-img {
    height: 50px;
    width: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
}

.notification-user-img {
    height: 50px;
    width: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

.notification-text {
    margin-bottom: 0;
    margin-left: 10px;
}

.notification-text-no-margin {
    margin-bottom: 0;
}

.inline-notification-text {
    margin-bottom: 0;
    margin-top: -5px;
    margin-left: 10px;
    width: calc(100% - 60px);
    display: inline-block;
}

.campaign-inline-div {
    width: calc(100% - 60px);
    display: inline-block;
}

.profile-dropdown {
    width: 275px;
    max-height: 525px;
    min-height: 200px;
    background-color: white;
    position: absolute;
    float: right;
    right: 30px;
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
}

.notification-dropdown {
    top: 40px;
    width: 300px;
    max-height: 400px;
    background-color: white;
    position: absolute;
    float: right;
    right: 0px;
    z-index: 20;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 10px;
    overflow: scroll;
}

.notification-div {
    margin-top: 25px;
}

.notification-h {
    text-align: center;
    margin-top: 10px;
    color: #396de0;
}

.notification-num-p {
    border-radius: 50%;
    width: 17px;
    height: 17px;
    background-color: red;
    color: white;
    position: absolute;
    top: 0px;
    right: 8px;
    text-align: center;
    line-height: 17px;
    font-size: 13px;
}

.notification-icon-div {
    position: relative;
    display: inline-block;
}

.elapsed-time-p {
    display: inline-block;
    color: #737373;
}

.notification-view-now-text {
    display: inline-block;
    color: black;
    font-weight: bold;
    margin-left: 10px;
}

.upload-btn {
    background-color: #c2ba50;
    color: white;
    border-radius: 25px;
    font-weight : bold;
    margin-right: 10px;
    padding: 1px 10px 1px 10px;
    border: 1px solid #c2ba50;
}

.mobile-ham {
    display: none;
}

.white-link {
    color: white;
}

.z-index-10 {
    z-index: 10;
}