@media only screen and (max-width: 480px) {
    .headshot-container {
        display: block;
    }

    .user-info {
        display: block;
        text-align: center;
        max-width: 100%;
    }

    .user-header {
        display: block;
        text-align: center;
    }

    .profile-aesthetic-div {
        display: none;
    }

    .first-name-margin {
        text-align: center;
        margin: 0;
    }

    .edit-div button {
        display: none;
    }

    .connect-with-brands-container {
        display: none !important;
    }
}
