.aesthetic-bar{
    width: 100%;
    padding-left: 1.25%;
    padding-right: 1.25%;
}

.aesthetic-a {
    font-weight: bold;
    border-right: 3px solid #c2ba50;
    padding-right: 15px;
}

.aesthetic-text {
    width: max-content;
    padding-top: 0;
    margin-top: -7px;
}

.selected-aesthetic {
    border-bottom: 3px solid #c2ba50;
}

.aesthetic-ul {
    overflow-x: scroll;
    width: calc(97.5vw - 160px);
    scroll-behavior: smooth;
    margin-bottom: -17px;
}

.aesthetic-div {
    position: relative;
    padding-top: 2px;
    margin-left: 3px;
}

.aesthetic-ul::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


.submit-aesthetic-div {
    margin-bottom: 0;
    height: 40px;
    padding: 6px 10px 8px 10px;
    float: right;
    border-radius: 15px;
}

.aesthetic-arrow-img {
    padding-top: 5px;
    height: 30px;
    width: auto;
    position: absolute;
    right: 0;
    padding-left: 60px;
    background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255, 1) 70%);
}

.left-aesthetic-arrow-img {
    padding-top: 5px;
    height: 30px;
    width: auto;
    float: right;
    position: absolute;
    background-image: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255, 1) 70%);
    padding-right: 60px;
}


.aesthetic-modal {
    width: 500px;
    margin: auto;
    background-color: white;
    margin-top: 20vh;
    border-radius: 25px;
    height: 350px;
}

.create-aesthetic-footer {
    width: 90%;
    margin-left: 5%;
}

.create-aesthetic-footer button {
    float: right;
    padding-left: 15px;
    padding-right: 15px;
}

.create-aesthetic-footer button:first-child {
    margin-left: 10px;
}

.aesthetic-submission-input {
    width: 90%;
    margin-left: 5%;
}

.aesthetic-submission-input p {
    font-size: 14px;
}


.new-aesthetic-header {
    width: 90%;
    margin-left: 5%;
    padding-top: 20px;
    padding-bottom: 10px;
}

.setting-img {
    height: 25px;
    width: auto;
}

.settings-dropdown {
    top: 30px;
    width: 200px;
    height: 150px;
    background-color: white;
    position: absolute;
    float: right;
    left: 20px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
}

.settings-btn-div {
    margin-top: 15px;
}

.apply-settings-btn {
    margin-left: 10px;
    width: 80px;
}