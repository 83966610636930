@media only screen and (max-width: 480px) {
    .tag-column {
        display: block;
        width: 100%;
    }
    .upload-column {
        width: 100%;
    }
    .upload-container {
        display: block;
    }
    .aesthetic-container {
        position: relative;
        top: 0px;
    }

    .label-container {
        position: relative;
        top: 20px;
    }

    .upload-lightbox {
        width: 100%;
        margin-left: 0;
    }

    .upload-background {
        padding-top: 0;
    }

    .upload-box {
        height: 50vh;
    }

    .upload-img {
        margin-top: 25px;
    }

    .upload-submit-div button {
        position: relative;
        margin-left: calc(50% - 37.5px);
        margin-top: 30px;
    }

    .label-input {
        width: 100%;
    }

    .aesthetic-input {
        width: 100%;
    }

    .aesthetic-dropdown {
        width: 100%;
    }

    .upload-modal {
        width: 100%;
        height: 100vh;
        background-color: white;
        border-radius: 25px;
        position: absolute;
        overflow: scroll;
    }

    .upload-submission-container {
        display: block;
    }

    .upload-image-preview {
        width: 75%;
        margin-left: 12.5%;
        display: block;
    }

    .upload-display {
        width: 90%;
        display: block;
        margin-left: 5%;
    }

    .dude-h {
        display: none;
    }

    .upload-submission-footer-div {
        position: relative;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding-bottom: 50px;
    }
}