
@media only screen and (max-width: 480px) {
    .image-column:first-child {
        display: inline-block;
        width: 45%;
        margin-left: 5%;
        vertical-align: top;
        position: relative;
    }

    .image-column {
        display: inline-block;
        width: 45%;
        margin-left: 2.5%;
        vertical-align: top;
        position: relative;
    }

    .user-info p {
        display: none;
    }

    .social-div {
        display: none;
    }

    .content-modal {
        width: 100%;
        height: 90vh;
        background-color: white;
        border: none;
        border-radius: 15px;
    }

    .content-summary {
        display: block;
    }

    .selected-content {
        display: block;
        position: relative;
        text-align: center;
    }

    .selected-content img {
        border-radius: 25px;
        margin-top: 2.5vh;
        margin-bottom: 2.5vh;
        width: auto;
        height: auto;
        max-height: 50vh;
        max-width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        object-fit: cover;
        margin-left: auto;
    }

    .content-info {
        display: block;
        width: calc(100% - 90px);
        margin-left: 45px;
    }

    .labels {
        display: none;
    }

    .download-btn {
        display: none;
    }

    .login-modal {
        width: 100%;
        margin: auto;
        height: 440px;
        margin-top: 20vh;
        background-color: white;
        border: none;
        border-radius: 15px;
        padding-top: 25px;
    }

    .top-button {
        display: none;
    }
}
