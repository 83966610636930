.following-tabs {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
}

.following-div {
    text-align: center;
}

.selected-tab {
    border-bottom: 3px solid #396de0;
}

.search-error-div {
    text-align: center;
    margin-top: 50px;
}

.search-error-div h3 {
    color: #396de0;
}

.mobile-upload-plus {
    display: none;
}

.app-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.popup-overlay {
    background: rgba(57, 109, 224, .5)
}

.h1 {
    color: #396de0;
    font-weight: 900;
    font-size: 70px;
    font-family: 'forma-djr-display', sans-serif;
    margin: 0;
}

.h2 {
    color: #396de0;
    font-weight: 900;
    font-size: 30px;
    font-family: 'forma-djr-display', sans-serif;
    margin: 0;
}

.p {
    font-size: 10px;
    font-family: 'lato', sans-serif;
    margin: 10px;
}

.close {
    align-self: end;
    color: grey;
    font-weight: 900;
}

@media only screen and (min-width: 480px) {
    #popup-root {
        display: none;
    }
}
