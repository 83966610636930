.Dropdown-root {
    background-color: #F1F1F1;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #396DE0;
    padding: 5px;
    display: flex;
    flex-direction: row;
}
.Dropdown-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
}
.Dropdown-arrow {
    content: '\2304';
    font-size: 30px;
    line-height: 23px;
    padding-right: 2px;
}