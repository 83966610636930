@media only screen and (max-width: 480px) {
    .im-in-btn {
        position: absolute;
        bottom: 10px;
        z-index: 5;
        width: 50%;
        left: 25%;
        font-size: 16px;
    }
}
