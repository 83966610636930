@media only screen and (max-width: 480px) {
    .dupe-navbar {
        width: 100%;
    }
    .search-bar input {
        width: calc(100% - 140px);
    }

    .second-nav-col {
        display: none;
    }

    .mobile-ham {
        display: inline-block;
        margin-left: 40px;
        float: right;
        margin-top: 5px;
    }

    .notification-icon-div {
        display: none;
    }

    .upload-btn-div {
        display: none;
    }

    .links {
        display: none;
    }

    .profile {
        display: none
    }

    .mobile-profile {
        display: inline-block;
    }

    .sidenav {
        width: 250px;
    }

    .side-x {
        margin-left: 15px;
        margin-top: 15px;
        color: lightgrey;
        font-size: 30px;
    }
}

@media only screen and (max-width: 1050px) {
    .links {
        display: none;
    }
}
