.about-us-div img {
    width: 100%;
    height: auto;
}

.about-us-div {
    border: none;
    position: relative;
}

.im-in-btn {
    position: absolute;
    bottom: 50px;
    z-index: 5;
    width: 25%;
    left: 37.5%;
    font-size: 26px;
}