.license-header-div h3 {
    display: inline-block;
    color:  #396de0;
    margin-left: 10px;
    margin-right: 10px;
}
.license-header-div {
    text-align: center;
}

.license-container {
    width: 70vw;
    height: 90vh;
    margin-top: 10px;
    margin-left: 15vw;
}

.selected-license-tab {
    text-decoration: underline;
}

.terms-div * {
    display: block;
    text-align: left;
    color: black;

}

.terms-div h5 {
    font-weight: bold;
}

.terms-div {
    margin-top: 20px;
    padding-bottom: 50px;
}
.license-div {
    color: black;
    margin-top: 20px;
}