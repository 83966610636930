
@media only screen and (max-width: 580px) {
    .homepage-search-input {
        position: absolute;
        top: 33.3%;
        left: 5%;
        width: 90%;
        height: 33.3%;
        border-radius: 50px;
        background-color: transparent;
        border: 3px solid white;
        padding-left: 30px;
        padding-right: 80px;
        color: white;
        font-size: xx-large;
    }

    .landing-search-img {
        display: inline-block;
        height: 6vh;
        width: auto;
        position: absolute;
        top: 40%;
        right: 10%;
    }

    .landing-login-div {
        display: none;
    }

    .logo-center-div {
        margin: auto;
    }

}
