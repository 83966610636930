.collection {
    width: 30vw;
    height: 25vh;
    margin-left: 2.5%;
    margin-top: 20px;
    display: inline-block;
}

.large-image {
    width: 19vw;
    height: 19vw;
    background-color: #EFEFEF;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    vertical-align:top;
    position: relative;
}

.large-collection-preview-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display:block;
}

.small-image {
    width: 9vw;
    height: 9vw;
    background-color: #EFEFEF;
    border-radius: 10px;
}

.small-container {
    display: inline-block;
    margin-left: 1vw;
}

.small-container div:first-child {
    margin-bottom: 1vw;
}

.collection-header-div {
    margin-left: 1.666%;
    width: 96.666%;
    margin-top: 20px;
}

.collection-header-div h3 {
    display: inline-block;
    margin-left: 15px;
}

.collection-header-div button {
    float: right;
    display: inline-block;
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.collection-header-div p {
    float: right;
    display: inline-block;
    margin-top: 5px;
}

.collection-header-div img {
    width: auto;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -10px;
}

.collection-description-div {
    width: 50%;
    margin-left: 25%;
    text-align: center;
    overflow-wrap: break-word;
}

.empty-collection-plus {
    width: 20%;
    height: 20%;
    border-radius: 10px;
    position: absolute;
    left: 40%;
    top: 40%;
    display:inline-block;
}

.create-collection-modal {
    width: 275px;
    height: 325px;
    background-color: white;
    border: none;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.edit-collection-form {
    width: 80%;
    margin-left: 10%;
    margin-top: 25px;
}

.edit-collection-modal {
    width: 500px;
    height: 300px;
    margin: auto;
    background-color: white;
    border: 1px white solid;
    border-radius: 15px;
    margin-top: 25vh;
}

.edit-collection-modal h5 {
    text-align: center;
}

.edit-collection-modal input {
    background-color: #EFEFEF;
    border: none;
}

.edit-collection-modal textarea {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #EFEFEF;
    border: none;
}

.edit-collection-btn-div {
    margin-top: 20px;
}
.edit-collection-btn-div button:last-child {
    float: right;
}

.edit-collection-btn-div p {
    display: inline-block;
}

.wait-jk-p {
    margin-left: 10px;
    text-decoration: underline;
}

.collection-name-h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.delete-collection-btn {
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-weight : bold;
    background-color: white;
    color: red;
    border: 1px solid red;
}

.delete-collection-btn:hover {
    background-color: red;
    color: white;
    border: none;
}

.empty-collection-div {
    text-align: center;
    margin-top: 25px;
}

.collection-lock-img {
    position: absolute;
    width: 40px;
    height: auto;
    top: 10px;
    left: 10px;
}

.secret-collection-header-div {
    text-align: center;
    width: 100%;
}

.secret-collection-header-div * {
    display: inline-block;
}

.secret-collection-header-div p {
    margin-bottom: 0;
    font-size: 14px;
    color: #737373;
}

.lock-header-img {
    width: 20px;
    height: auto;
}

.upload-portfolio-modal {
    width: 50vw;
    height: auto;
    margin-left: 25%;
    margin-top: 5vh;
    background-color: white;
    border-radius: 25px;
}

.new-collection-img {
    float: right;
    height: 20px;
    width: auto;
    margin-right: 5px;
    margin-top: 3px;
}

.new-collection-h5 {
    float: right;
    margin-bottom: 0;
}

.new-collection-div {
    width: 100%;
    display: block;
    height: 50px;
    padding-top: 16px;
    padding-right: 25px;
}
