.signup-img {
    width: 100%;
    height: 101vh;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-top: -.5vh;
}

.validation-error {
    color: red;
}
