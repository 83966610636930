@media only screen and (max-width: 480px) {
    .mobile-upload-plus {
        position: fixed;
        left: calc(50% - 22px);
        bottom: 15px;
        background-color: #396de0;
        z-index: 5;
        display: block;
        font-size: 36px;
        font-weight: 1000;
        color: #EFEFEF;
        border-radius: 50%;
        width: 54px;
        text-align: center;
    }
}