.trending-aesthetic-div {
    width: 30vw;
    height: auto;
    display: inline-block;
    margin-left: 2.5vw;
    vertical-align: top;
    position: relative;
}

.trending-aesthetic-div img {
    width: 100%;
    height: 30vh;
    border-radius: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-bottom: 20px;
    filter: brightness(70%);
}

.trending-aesthetic-h5 {
    margin-left: 2.5vw;
}

.trending-aesthetic-container h3 {
    text-align: center;
    margin-top: 25px;
}


.trending-aesthetic-text {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    border: 1px solid white;
    padding: 10px 10px 10px 10px;
    border-radius: 25px;
    min-width: 40%;
    text-align: center;
}
