.homepage-img-div {
    width: 25vw;
    height: calc((100vh - 62px) / 3);
    display: inline-block;
}

.homepage-img-div img {
    width: 100%;
    height: calc((100vh - 62px) / 3);
    object-fit: cover;
    vertical-align: baseline;
    filter: brightness(80%);
}

.homepage-row-div {
    width: 100vw;
    height: calc((100vh - 62px) / 3);
    position: relative;
}

.content-preview-div {
    width: 20%;
    height: auto;
    display: inline-block;
}

.homepage-modal {
    width: 90vw;
    height: 90vh;
    margin: auto;
    margin-top: 5vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    overflow: scroll;
}

.homepage-div {
}

.homepage-search-input {
    position: absolute;
    top: 33.3%;
    left: 27.5%;
    width: 45%;
    height: 33.3%;
    border-radius: 50px;
    background-color: transparent;
    border: 3px solid white;
    padding-left: 30px;
    padding-right: 100px;
    color: white;
    font-size: xx-large;
}

.homepage-search-input::placeholder {
    color: white;
    font-size: xx-large;
}

.homepage-save-btn {
    position: absolute;
    left: 45%;
    bottom: 0;
    width: 10%;
}

.blue-logo-img {
    height: 42px;
    width: auto;
    margin: auto;
}

.landing-login-div {
    padding-left: 15px;
}

.scroll-snap-parent {
    width: 100%;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow: scroll;
    scroll-behavior: smooth;
    scroll-snap-stop: normal;
}

.scroll-child {
    scroll-snap-align: start;
}

.scroll-div {
    overflow: scroll;
}

.landing-search-img {
    display: inline-block;
    height: 6vh;
    width: auto;
    position: absolute;
    top: 40%;
    right: 30%;

}

.landing-down-img {
    width: 3vw;
    height: auto;
    position: absolute;
    left: 48.5%;
    top: 70%;
}

.logo-center-div {
    text-align: center;
}

.logo-center-div * {
    display: inline-block;
    font-family: 'forma-djr-display', sans-serif;
    font-size: 20px;
    margin-bottom: 0;
}
