.inline-header {
    display: inline-block;
    margin-right: 20px;
}

.hire-container {
    text-align: center;
    padding-bottom: 10vh;
}

.input-row {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.hire-img-2 {
    width: 30%;
    margin-right: 5%;
    margin-left: 5%;
    height: auto;
    display: inline-block;
}

.hire-img-3 {
    width: 20%;
    margin-right: 5%;
    margin-left: 5%;
    height: auto;
    display: inline-block;
}

.hire-img-4 {
    width: 15%;
    margin-right: 5%;
    margin-left: 5%;
    height: auto;
    display: inline-block;
    vertical-align: top;
}

.hire-img-4 img {
    width: 100%;
    height: auto;
}

.available-selected {
    border-bottom: 3px solid #396de0;
}

.hiring-selected {
    border: 5px solid #396de0;
}